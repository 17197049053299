import axios from '@axios'
import myCustomJwt from './myCustomJwt'

const jwtConfig = {
  // Endpoints
  // eslint-disable-next-line prefer-template
  loginEndpoint: `${process.env.VUE_APP_API_URL}login`,
  registerEndpoint: `${process.env.VUE_APP_API_URL}register`,
  refreshEndpoint: `${process.env.VUE_APP_API_URL}refresh-token`,
  logoutEndpoint: `${process.env.VUE_APP_API_URL}logout`,
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: `${process.env.VUE_APP_STORAGE_ACCESS_CREDENTIAL_NAME}`,
  storageRefreshTokenKeyName: `${process.env.VUE_APP_STORAGE_REFRESH_CREDENTIAL_NAME}`,
}

const { jwt } = myCustomJwt(axios, jwtConfig)
export default jwt
